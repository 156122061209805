import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

import client_actionaid from '../assets/images/logos/actionaid.png'
import client_aoc from '../assets/images/logos/aoc.png'
import client_bdo from '../assets/images/logos/bdo.png'
import client_chatham from '../assets/images/logos/chatham.svg'
import client_clic from '../assets/images/logos/clic.png'
import client_csp from '../assets/images/logos/csp.png'
import client_nics from '../assets/images/logos/nics.png'
import client_donk from '../assets/images/logos/donk.png'
import client_fsa from '../assets/images/logos/fsa.png'
import client_greenpeace from '../assets/images/logos/greenpeace.png'
import client_itv from '../assets/images/logos/itv.png'
import client_jrf from '../assets/images/logos/jrf.png'
import client_kf from '../assets/images/logos/kf.png'
import client_nuffield from '../assets/images/logos/nuffield.png'
import client_ox from '../assets/images/logos/ox.png'
import client_oxfam from '../assets/images/logos/oxfam.png'
import client_plan from '../assets/images/logos/plan.png'
import client_rau from '../assets/images/logos/rau.png'
import client_tuc from '../assets/images/logos/tuc.png'
import client_ukgbc from '../assets/images/logos/ukgbc.png'
import client_sheffield from '../assets/images/logos/sheffield.png'
import client_wrap from '../assets/images/logos/wrap.png'

class Homepage extends React.Component {
    render() {
        const siteTitle = "whoisjohan.com | Homepage";

        return (
            <Layout>
                <Helmet title={siteTitle} />

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2><a name="about">About me</a></h2>
                            </header>                            
                            <p>I've spent the best part of my career in leading UK digital agencies working with globally reknowned organisations and teams. I'm now in a hands-on consultant/developer role working in a formative but active technical ecosystem in the UK.</p>
                            <p>I've had successful roles as a project lead, technical architect, lead developer and software engineer. I also have extensive leadership experience as a technical team leader and mentor.</p>
                            <p>I have extensive experience of a range of project delivery methodologies, including contemporary Agile processes. I prefer to focus on outcomes over output and specific processes, and enjoy cultivating an evidence-led and flexible approach to software development.</p>
                        </div>
                    </div>
                </section>

                <section id="two" className="main style2 services">
                <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2><a name="work">How can I help you?</a></h2>
                            </header>  
                        </div>
                </div>
                <div className="grid-wrapper">
                        <div className="col-2">
                            <ul className="major-icons">
                                <li><span className="icon style7 major fa-code"></span></li>
                            </ul>
                        </div>
                        <div className="col-10">
                            <h3>Development</h3>
                            <p>I come from a background of web development with tools and frameworks including Drupal 8, GatsbyJS/React and to a lesser but functional extent: Python, Wordpress and node.js. I use tools like Docker and Vagrant every day, and I actively try not to pigeon-hole myself as a backend or frontend developer, or a devops engineer. Most projects I work on touch a part of all these things, and that's okay with me.
                            </p>
                            <p>I always try to understand and tailor my work to the wider context of the organisation I operate in and encourage other developers to do the same.</p>
                        </div>
                    </div>

                    <div className="grid-wrapper">
                        <div className="col-2">
                            <ul className="major-icons">
                                <li><span className="icon style1 major fa-cog"></span></li>
                            </ul>
                        </div>
                        <div className="col-10">
                            <h3>Consultancy</h3>
                            <p>Understanding how - or even if - to build something is can be extremely helpful to business leaders or digital teams. I can help to review and validate your organisation's technical or digital strategy and ensure that decisions, investments and team goals are well aligned to minimise waste.</p>
                            <p>I'm a firm believer that the best technical consultants still have some kind of hands-on experience with a project or one similar to it.</p>
                        </div>
                    </div>

                    <div className="grid-wrapper">
                        <div className="col-2">
                            <ul className="major-icons">
                                <li><span className="icon style3 major fa-bolt"></span></li>
                            </ul>
                        </div>
                        <div className="col-10">
                            <h3>Technical leadership</h3>
                            <p>I have years of hands-on experience recruiting, building and managing specialist teams of developers - both remote and on-site - as well as advocating and forming technical strategy. I can communicate clearly with board-level staff as well as technical staff, devise and deliver personal development plans and have a broad experience of modern day development tools and processes to fit around an organisation's strategic goals.</p>
                            <p>I have years of experience with client pitches, leading interviews and team resource planning.</p>
                        </div>
                    </div>

                    <div className="grid-wrapper">
                        <div className="col-2">
                            <ul className="major-icons">
                                <li><span className="icon style2 major fa-user"></span></li>
                            </ul>
                        </div>
                        <div className="col-10">
                            <h3>Coaching and mentoring</h3>
                            <p>Coaching offers skilled technical staff a sounding board for tackling problems and encouraging self-development. I can provide coaching and mentoring skills for developers moving into more managerial roles as well as encouraging existing technical staff to further their interests and capabilities.</p>
                        </div>
                    </div>
                    
                </section>

                <section id="three" className="main style1 special">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2><a name="clients">Clients</a></h2>
                            </header>                            
                            <p>I have worked with clients from a wide range of sectors ranging from government and public sector services,<br /> charities and 
                                not-for-profit organisations, higher education, UK media, and professional services and European startups.</p>
                        </div>

                        <div className="col-3">
                            <span className="image fit"><img src={client_nics} alt="Northern Ireland Civil Service logo" /></span>
                            <p class="brief">Development, consultancy, technical training, cloud hosting procurement and migration.</p>
                        </div>
                        <div className="col-3">
                            <span className="image fit"><img src={client_fsa} alt="Food Standards Agency logo" /></span>
                            <p class="brief">Development, consultancy and delivery of cloud hosting with multiple third party partners.</p>
                        </div>                        
                        <div className="col-3">
                            <span className="image fit"><img src={client_itv} alt="ITV logo" /></span>
                            <p class="brief">Management and oversight of entire technical team for high profile press centre project.</p>
                        </div>
                        <div className="col-3">
                            <span className="image fit" style={{ background: '#005984', margin: '2em 0 1em 0', 'min-height': '100px' }}><img src={client_chatham} alt="Chatham House logo" /></span>
                            <p class="brief">Technical lead + development, complex systems integration for world-leading policy institute.</p>
                        </div>

                    </div>
                    <div className="grid-wrapper">

                        <div className="col-2">
                            <span className="image fit"><img src={client_greenpeace} alt="Greenpeace logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_actionaid} alt="ActionAid logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_plan} alt="Plan International logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_nuffield} alt="Nuffield Trust logo" /></span>                            
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_kf} alt="The King's Fund logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_oxfam} alt="Oxfam logo" /></span>
                        </div>

                    </div>
                    <div className="grid-wrapper">

                        <div className="col-2">
                            <span className="image fit"><img src={client_tuc} alt="Trades Union Congress logo" /></span>
                        </div>                        
                        <div className="col-2">
                            <span className="image fit"><img src={client_ox} alt="Univeristy of Oxford logo" /></span>
                        </div>                        
                        <div className="col-2">
                            <span className="image fit"><img src={client_sheffield} alt="University of Sheffield logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_rau} alt="Royal Agricultural University logo" /></span>                            
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_aoc} alt="Association of Colleges logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_donk} alt="The Donkey Sanctuary logo" /></span>
                        </div>                        

                    </div>

                    <div className="grid-wrapper">

                        <div className="col-2">
                            <span className="image fit"><img src={client_clic} alt="CLIC Sargent logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_csp} alt="Chartered Society of Physiotherapists logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_jrf} alt="Joseph Rowntree Foundation logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_wrap} alt="WRAP logo" /></span>
                        </div>                        
                        <div className="col-2">
                            <span className="image fit"><img src={client_ukgbc} alt="UK Green Building Council logo" /></span>
                        </div>
                        <div className="col-2">
                            <span className="image fit"><img src={client_bdo} alt="BDO logo" /></span>                            
                        </div>                        

                    </div>

                </section>

            </Layout>
        );
    }
}

export default Homepage;
